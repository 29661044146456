import Footer from "../components/Footer";
import Header from "../components/Header";

const Career = () => {
  return (
    <>

      <div>
        <div id="skip-link">
          <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
        </div>
        <div className="body-innerwrapper">
          <Header />
          <div className="region region-banner">
            <section id="block-views-inner-banner-block" className="block block-views clearfix">
              <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-f424f99d3e863c8d2c0f39b5ae70ef08">
                <div className="view-content">
                  <div className="skin-default">
                    <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                      <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                        <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                          <div className="views-field views-field-field-slide-image">        <div className="field-content" /></div></div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="main-container container" style={{ width: '100%',maxWidth:'100%', margin: 0 }}>
            <header role="banner" id="page-header">
            </header> {/* /#page-header */}
            <div className="row">
              <section className="col-sm-12">
                <a id="main-content" />
                <h1 className="page-header">Career</h1>
                <div className="region region-content">
                  <section id="block-system-main" className="block block-system clearfix">
                    <article id="node-83" className="node node-page clearfix" about="/career" typeof="foaf:Document">
                      <header>
                        <span property="dc:title" content="Career" className="rdf-meta element-hidden" />    </header>
                      <div className="field field-name-body field-type-text-with-summary field-label-hidden"><div className="field-items"><div className="field-item even" property="content:encoded"><h3>Career</h3>
                        <p>Pursuing a nursing career abroad can be a great opportunity, as many countries have a high demand for skilled nurses. Here are some key points to consider if you're looking to work as a nurse internationally:
                        </p>
                        <ol>
                          <li>Licensing Requirements: l</li>


<li>Educational Equivalency: </li>

<li>Language Proficiency: </li>


<li>Job Demand and Work Conditions: </li>


<li>Visa and Immigration Process: </li>


<li>Professional Development: </li>


<li>Interlink Programs and Partnerships:</li>
                        </ol>
                      </div></div></div>  </article>
                  </section>
                </div>
              </section>
            </div>
          </div>
          <div className="region region-content-bottom-bottom">
            <section id="block-block-9" className="block block-block clearfix">
              <div className="center">
                <p className="lead">Are you ready to earn your nursing degree?
                  <a className="btn" href="/contact">Contact Us to know more</a></p>
              </div>
            </section>
          </div>

          <Footer />
        </div>
      </div>

    </>
  )
}
export default Career;