import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Admission = () => {


    // Define state variables for each input field
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [course, setCourse] = useState('');

    // Handle change for each input field
    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleCourseChange = (e) => {
        setCourse(e.target.value);
    };
    const validatePhoneNumber = (phoneNumber) => {
        // Basic phone number validation (supports formats like 1234567890, (123) 456-7890, or 123-456-7890)
        const phoneRegex = /^(\+?\d{1,4}[\s-]?)?(\(?\d{3}\)?[\s\-]?)?[\d\s\-]{7,13}$/;
        return phoneRegex.test(phoneNumber);
      };
    // Handle form submission
    const handleSubmit =async (e) => {
        e.preventDefault(); // Prevent the default form submission (page reload)
        // You can handle the form data here (e.g., send it to an API)
        console.log('Form Submitted:', { name, email, phone, course });
        // You can also reset the form fields if needed
        if (!name || !email || !phone || !course) {
            alert('All fields are required.');
            return;
          }
          if (!validatePhoneNumber(phone)) {
            alert('Please enter a valid phone number');
            return;
          }
          
        // const formData = {
        //     name,
        //     email,
        //     phone,
        //     course,
        //   };

          const formData =  {
            "name": name,
            "email":email,
            "mobile_no":phone,
            "course":course
      }
      
          try {
            // Make POST request using Axios
            const response = await axios.post('https://api.sophiacollegeofnursing.com/addAdmission', formData);
            
            // Handle success
            // setSuccess('Form submitted successfully!');
            console.log('Response:', response.data);
            alert('submitted successfully!');
            setName('');
            setEmail('');
            setPhone('');
            setCourse('');
          } catch (err) {
            // Handle error
            // setError('There was an error submitting the form. Please try again.');
            alert('There was an error submitting the form. Please try again.');
            console.error('Error:', err);
            setName('');
            setEmail('');
            setPhone('');
            setCourse('');
          } finally {
            // Set loading to false after request is done
            // setLoading(false);
          }
    };


    return (<>
        <div>
            <div id="skip-link">
                <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
            </div>
            <div className="body-innerwrapper">
                <Header />
                <div className="region region-banner">
                    <section id="block-views-inner-banner-block" className="block block-views clearfix">
                        <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-6aed6aeeaec570c322c6ef1907a58278">
                            <div className="view-content">
                                <div className="skin-default">
                                    <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main">
                                        <div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">

                                            <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                                                <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">

                                                    <div className="views-field views-field-field-slide-image">

                                                        <div className="field-content">

                                                            <img typeof="foaf:Image" className="img-responsive" src="./images/admission-banner.jpg" width={'100%'} height={'auto'} alt />

                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section id="block-webform-client-block-82" className="block block-webform clearfix">
                        <article id="node-82" className="node node-webform clearfix" about="/get-admission" typeof="sioc:Item foaf:Document">
                            <header>
                                <h2><a href="/get-admission">Get Admission</a></h2>
                                <span property="dc:title" content="Get Admission" className="rdf-meta element-hidden" />    </header>
                            <div className="field field-name-body field-type-text-with-summary field-label-hidden">


                                <div className="field-items"><div className="field-item even" property="content:encoded">

                                    <h1>Admission /Enquiry Opened...!!</h1>
                                    <p>Limited seats are available now, Contact Us to Know more</p>
                                </div>

                                </div>

                            </div>


                            <form onSubmit={handleSubmit} className="webform-client-form webform-client-form-82">

                                <div>

                                    <div className="form-item webform-component webform-component-textfield webform-component--name form-group form-item form-item-submitted-name form-type-textfield form-group">

                                        <label className="control-label" htmlFor="edit-submitted-name">Name <span className="form-required" title="This field is required.">*</span>

                                        </label>
                                        <input required="required" className="form-control form-text required" type="text" name="name" value={name} onChange={handleNameChange} size={60} maxLength={128} />

                                    </div>

                                    <div className="form-item webform-component webform-component-email webform-component--email form-group form-item form-item-submitted-email form-type-webform-email form-group">


                                        <label className="control-label" htmlFor="edit-submitted-email">Email
                                            <span className="form-required" title="This field is required.">*</span>
                                        </label>

                                        <input required="required" className="email form-control form-text form-email required" type="email" name="email" value={email}
                                            onChange={handleEmailChange} size={60} />

                                    </div>

                                    <div className="form-item webform-component webform-component-textfield webform-component--phone form-group form-item form-item-submitted-phone form-type-textfield form-group">
                                        <label className="control-label" htmlFor="edit-submitted-phone">Phone <span className="form-required" title="This field is required.">*</span></label>

                                        <input required="required" className="form-control form-text required" type="text" id="edit-submitted-phone" name="phone" value={phone} onChange={handlePhoneChange} size={60} maxLength={128} />

                                    </div>

                                    <div className="form-item webform-component webform-component-select webform-component--required-course form-group form-item form-item-submitted-required-course form-type-select form-group">
                                        <label className="control-label" htmlFor="edit-submitted-required-course">Select the Course <span className="form-required" title="This field is required.">*</span>
                                        </label>

                                        <select required="required" className="form-control form-select required" name="course" value={course}
                                            onChange={handleCourseChange}>
                                            <option value selected="selected">- Select -</option>
                                            {/* <option value="MSc">M.Sc.Nursing</option> */}
                                            <option value="BSc">B.Sc.Nursing</option>
                                            {/* <option value="GNM">GNM</option> */}
                                        </select>
                                    </div>
                                    {/* <input type="hidden" name="details[sid]" /> */}
                                    {/* <input type="hidden" name="details[page_num]" defaultValue={1} /> */}
                                    {/* <input type="hidden" name="details[page_count]" defaultValue={1} /> */}
                                    {/* <input type="hidden" name="details[finished]" defaultValue={0} /> */}
                                    {/* <input type="hidden" name="form_build_id" defaultValue="form-c6fTOnT3KKjXqWX_185cCsqxprPB0lejET6odUn5qD0" /> */}
                                    {/* <input type="hidden" name="form_id" defaultValue="webform_client_form_82" /> */}


                                    <div className="edit-course-name-wrapper"><div className="form-item form-item-course-name form-type-textfield form-group">

                                        {/* <label className="control-label" htmlFor="edit-course-name">course name</label> */}
                                        {/* <input type="text" id="edit-course-name" name="course_name" defaultValue size={20} maxLength={128} className="form-text" /> */}


                                        <div className="help-block">To prevent automated spam submissions leave this field empty.</div></div></div><div className="form-actions">


                                        <button className="webform-submit button-primary btn btn-primary form-submit" type="submit" name="op" value="submit">Submit</button>
                                    </div>

                                </div>

                            </form>

                        </article>
                    </section>
                </div>

                {/* <div className="region region-content-bottom">
                    <section id="block-block-33" className="block block-block clearfix">
                        <div className="color-boxes">
                            <div className="col-sm-4 col">
                                <div className="box-container">
                                    <div className="box-card">
                                        <div className="front face">
                                            <h2>About</h2>
                                        </div>
                                        <div className="back face">
                                            <h2>About</h2>
                                            <Link to="/about">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col">
                                <div className="box-container">
                                    <div className="box-card">
                                        <div className="front face">
                                            <h2>Courses</h2>
                                        </div>
                                        <div className="back face">
                                            <h2>Courses</h2>
                                            <Link href="/bscnursing">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 col">
                                <div className="box-container">
                                    <div className="box-card">
                                        <div className="front face">
                                            <h2>Facilities</h2>
                                        </div>
                                        <div className="back face">
                                            <h2>Facilities</h2>
                                            <Link href="/facilities">Read More</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div> */}
                {/* <div className="region region-content-bottom-bottom">
                    <section id="block-block-9" className="block block-block clearfix">
                        <div className="center">
                            <p className="lead">Are you ready to earn your nursing degree?
                                <Link className="btn" href="/Contact">Contact Us to know more</Link></p>
                        </div>
                    </section>
                </div> */}

<div className="region region-content-bottom-bottom">
                    {/* <section id="block-block-20" className="block block-block clearfix">
                        <div className="text-block" id="text-block-1">
                            <div className="row">
                                <p className="text-center text-uppercase"><small>Our Specialities</small></p>
                                <p className="lead text-center">We believe in Discipline, Quality and Professionalism. <br />Our vision is to focus on the students, giving them education and skills so they can perform better in both profession and in life.</p>
                                <div className="blocks-within-textblock">
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-content-bottom.png" />
                                        <p className="text-center">Discipline and routine are the keys to success, especially in education. A disciplined person can stay focused, manage their time effectively, and consistently work toward their goals. This mindset helps them overcome obstacles and achieve long-term success in life.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-2-content-bottom.png" />
                                        <p className="text-center">Quality education is the foundation of success. By prioritizing excellence in our teaching, we ensure that our students are well-prepared and stand out ahead of their competitors.</p>
                                    </div>
                                    <div className="col-sm-4">
                                        <img className="center-block" src="sites/all/themes/sushrutha/images/icon-blue-3-content-bottom.png" />
                                        <p className="text-center">In today’s era of global opportunities, our education system is designed to equip students with the skills and knowledge needed to excel on the world stage. We are preparing professionals ready to seize global opportunities and thrive in a competitive environment.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section> */}
                    {/* <section id="block-block-4" className="block block-block clearfix">
                        <p className="lead text-center">"Nursing is an art: and if it is to be made an art, it requires an exclusive devotion as hard a preparation, as any painter's or sculptor's work."</p>
                        <h3 className="text-center"><em> - Florence Nightingale</em><br /><span className="small">Founder of Modern Nursing</span></h3>
                    </section> */}
                    <section id="block-block-9" className="block block-block clearfix">
                        <div className="center">
                        <br/>  <br/>  <br/>  <br/>
                            <p className="lead">Are you ready to earn your nursing degree?
                              <br/>  <Link className="btn" to="contact">Contact Us to know more</Link></p>
                        </div>
                    </section>
                </div>

                <Footer />
            </div>
        </div>


    </>)
}
export default Admission;