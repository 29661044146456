// VideoSlider.js
import React, { useEffect, useRef } from 'react';
// import { Link } from 'react-router-dom';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css'; // Import styles

const VideoSlider = () => {

  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    const handleVideoPlay = () => {
      video.play().catch((error) => {
        console.error("Autoplay failed:", error);
      });
    };

    // Trigger play when video is loaded
    if (video) {
      video.addEventListener('loadeddata', handleVideoPlay);
    }

    return () => {
      if (video) {
        video.removeEventListener('loadeddata', handleVideoPlay);
      }
    };
  }, []);
  return (
    // style={{opacity: '0.5'}}
    <div className="video-slider-container">
      <AwesomeSlider bullets={false}>
        {/* Slide 1 autoPlay */}
        <div style={{ width: '100%' }}>
          <video
            ref={videoRef}
            autoPlay
            muted
            loop
            playsInline
            style={{ width: '100%' }}
          >
            <source src="https://sophiacollegeofnursing.com/nursing-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div className="caption">
            {/* <h3 style={{ textShadow: '2px 2px #000' }}>Welcome To <br /> Sophia College <br /> Of Nursing</h3> */}
            {/* <Link to="/admission" style={{ textDecoration: 'none', backgroundColor: '#fff' }}>Apply Now</Link> &nbsp; */}
            {/* style={{textDecoration:'none',color:'#fff !important',backgroundColor:'#fb6304'}} */}
            {/* <button onClick={() => alert('Learn More clicked for Video 2')}>Learn More</button> */}
          </div>
        </div>

        {/* Slide 2 */}
        {/* <div>
          <video autoPlay muted loop>
            <source src="video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="caption">
            <h3>Caption for Video 2</h3>
            <button onClick={() => alert('Learn More clicked for Video 2')}>Learn More</button>
          </div>
        </div> */}

        {/* Slide 3 */}
        {/* <div>
          <video autoPlay muted loop>
            <source src="video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="caption">
            <h3>Caption for Video 3</h3>
            <button onClick={() => alert('Learn More clicked for Video 2')}>Learn More</button>
          </div>
        </div> */}

      </AwesomeSlider>
    </div>
  );
};

export default VideoSlider;
