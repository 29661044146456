import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import ImageSlider from "../components/ImageSlider";

const Facilities = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [labImage, setLabImage] = useState(['./images/lab/1.jpg',
    './images/lab/2.jpg',
    './images/lab/3.jpg',
    './images/lab/4.jpg',
    './images/lab/5.jpg',
    './images/lab/6.jpg']);
  // Function to go to the next image
  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % labImage.length);
  };

  useEffect(() => {
    nextImage();
    prevImage();
  }, [])
  // Function to go to the previous image
  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? labImage.length - 1 : prevIndex - 1
    );
  };




  const [hospitalImage, setHospitalImage] = useState(['./images/hospital/1.jpg',
    './images/hospital/2.jpg',
    './images/hospital/3.jpg',
    './images/hospital/4.jpg',
    './images/hospital/5.jpg',
    './images/hospital/6.jpg',
    './images/hospital/7.jpg',
    './images/hospital/8.jpg',
    './images/hospital/9.jpg',
    './images/hospital/10.jpg',
    './images/hospital/11.jpg',
    './images/hospital/12.jpg',
    './images/hospital/13.jpg',
    './images/hospital/14.jpg',
    './images/hospital/15.jpg',
    './images/hospital/16.jpg',

  ]);


  return (
    <>

      <div>
        <div id="skip-link">
          <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
        </div>
        <div className="body-innerwrapper">
          <Header />
          <div className="region region-banner">
            <section id="block-views-inner-banner-block" className="block block-views clearfix">
              <div className="view view-inner-banner view-id-inner_banner view-display-id-block view-dom-id-37cabe28394de6a34d7d49737614e7a2">
                <div className="view-content">
                  <div className="skin-default">
                    <div id="views_slideshow_cycle_main_inner_banner-block_1" className="views_slideshow_cycle_main views_slideshow_main"><div id="views_slideshow_cycle_teaser_section_inner_banner-block_1" className="views-slideshow-cycle-main-frame views_slideshow_cycle_teaser_section">
                      <div id="views_slideshow_cycle_div_inner_banner-block_1_0" className="views-slideshow-cycle-main-frame-row views_slideshow_cycle_slide views_slideshow_slide views-row-1 views-row-first views-row-odd">
                        <div className="views-slideshow-cycle-main-frame-row-item views-row views-row-0 views-row-odd views-row-first">
                          <div className="views-field views-field-field-slide-image">        <div className="field-content" /></div></div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="main-container container" style={{ width: '100%', maxWidth: '100%', margin: 0 }}>
            <header role="banner" id="page-header">
            </header> {/* /#page-header */}
            <div className="row">
              <section className="col-sm-12">
                <ol className="breadcrumb"><li><span title className="nolink" tabIndex={0}>College</span></li>
                  <li className="active">Facilities</li>
                </ol>      <a id="main-content" />
                <h1 className="page-header">Facilities</h1>
                <div className="region region-content">
                  <section id="block-system-main" className="block block-system clearfix">
                    <article id="node-61" className="node node-page clearfix" about="/facilities" typeof="foaf:Document">
                      <header>
                        <span property="dc:title" content="Facilities" className="rdf-meta element-hidden" />    </header>
                      <div className="field field-name-body field-type-text-with-summary field-label-hidden"><div className="field-items"><div className="field-item even" property="content:encoded"><div className="content-block">
                        <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            <img className="img-responsive enlarge" src="./images/class-room.jpg" />
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2>Class Rooms</h2>
                            <p>The college is furnished with spacious &amp; well ventilated classrooms, equipped with the latest teaching aids such as LCD Projector, OHP to have a highly interactive session in classroom teaching.</p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image">
                            <img className="img-responsive enlarge" src="./images/library.jpg" />
                          </div>
                          <div className="col-sm-6 text text-on-right">
                            <h2>Library</h2>
                            <p>The college has  its own library that provides latest &amp; up to date reference &amp; textbooks for all nursing courses. A large number of national &amp; international journals through HELINET consortium, periodicals, news papers &amp; health magazines are being subscribed. Digital library with internet connection and large number of educational video tapes &amp; CD-ROMS on different subjects are made available. </p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            {/* <img className="img-responsive enlarge" src="/sites/all/themes/sushrutha/images/laboratories.jpg" /> */}
                            {/* delete content */}
                            <div id="myCarousel" className="carousel slide" data-ride="carousel">

                              <div className="carousel-inner">
                                {labImage.map((image, index) => (

                                  <div className={`${index === currentIndex ? "item active" : "item"}`}>
                                    <img src={image} alt="Los Angeles" style={{ width: '100%' }} />

                                  </div>
                                ))}
                              </div>
                              {/* Left and right controls */}
                              <a className="left carousel-control" href="#myCarousel" data-slide="prev">
                                <span className="glyphicon glyphicon-chevron-left" />
                                <span className="sr-only">Previous</span>
                              </a>
                              <a className="right carousel-control" href="#myCarousel" data-slide="next">
                                <span className="glyphicon glyphicon-chevron-right" />
                                <span className="sr-only">Next</span>
                              </a>
                            </div>


                            {/* delete content */}
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2>Laboratories</h2>
                            <p>The College has well advanced nursing skill laboratories to teach various nursing procedures prior and throughout the Clinical Experience. All the laboratories are provided with adequate number of equipments and instruments. </p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image">
                            {/* <img className="img-responsive enlarge" src="/sites/all/themes/sushrutha/images/hospitals.jpg" /> */}
                            {/* <ImageSlider data={hospitalImage} /> */}
                            <div id="myCarousel1" className="carousel slide" data-ride="carousel">

                              <div className="carousel-inner">
                                {hospitalImage.map((image, index) => (

                                  <div className={`${index === currentIndex ? "item active" : "item"}`}>
                                    <img src={image} alt="Los Angeles" style={{ width: '100%' }} />

                                  </div>
                                ))}
                              </div>
                              {/* Left and right controls */}
                              <a className="left carousel-control" href="#myCarousel1" data-slide="prev">
                                <span className="glyphicon glyphicon-chevron-left" />
                                <span className="sr-only">Previous</span>
                              </a>
                              <a className="right carousel-control" href="#myCarousel1" data-slide="next">
                                <span className="glyphicon glyphicon-chevron-right" />
                                <span className="sr-only">Next</span>
                              </a>
                            </div>
                          </div>
                          <div className="col-sm-6 text text-on-right">
                            <h2>Hospitals</h2>
                            <p>The College is associated with parent hospital, Government  and corporate multi speciality hospitals for the purpose of clinical training through out the professional course.</p>
                          </div>
                        </div>
                        <div className="row bottom-space">
                          <div className="col-sm-6 image image-right">
                            <img className="img-responsive enlarge" src="./images/faculty.png" />
                          </div>
                          <div className="col-sm-6 text text-on-left">
                            <h2>Faculty</h2>
                            <p>We have hired highly qualiﬁed, efﬁcient &amp; experienced teaching faculties (Ratio 1:10) including visiting Professors for various subjects from renowned institutes . workshops, Symposium and seminars are being conducted regularly by inviting eminent scholars,resourceful dignitaries and teachers who are well versed in Nursing education.</p>
                          </div>
                        </div>
                      </div></div></div></div>  </article>
                  </section>
                </div>
              </section>
            </div>
          </div>
          <div className="region region-content-bottom-bottom">
            <section id="block-block-9" className="block block-block clearfix">
              <div className="center">
                <p className="lead">Are you ready to earn your nursing degree?
                  <a className="btn" href="/contact">Contact Us to know more</a></p>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>

    </>
  )
}

export default Facilities;