import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

const Photos = () => {
  return (<>

    <div>
      <div id="skip-link">
        <a href="#main-content" className="element-invisible element-focusable">Skip to main content</a>
      </div>
      <div className="body-innerwrapper">
        <Header />
        <div className="main-container container" style={{ width: '100%', maxWidth: '100%', margin: 0 }}>
          <header role="banner" id="page-header">
          </header> {/* /#page-header */}
          <div className="row">
            <section className="col-sm-12">
              <a id="main-content" />
              <h1 className="page-header">Photos</h1>
              <div className="region region-content">
                <section id="block-system-main" className="block block-system clearfix">
                  <div className="view view-photos view-id-photos view-display-id-page view-dom-id-e19b9e14d09feb2683b9176ecf3492e5">
                    <div className="view-content">
                      <div className="views-row views-row-1 views-row-odd views-row-first">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/2023"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/WhatsApp%20Image%202023-12-12%20at%2011.57.04%20%281%29.jpeg?itok=li5Fbv5Z" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/2023">2023</a></span></div></div>
                      <div className="views-row views-row-2 views-row-even">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/world-environment-day"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_DSC_0032.JPG.jpg?itok=rB_0lJUE" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/world-environment-day">World Environment Day</a></span></div></div>
                      <div className="views-row views-row-3 views-row-odd">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/sports"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_DSC_0014.JPG.jpg?itok=EY6ZcgJR" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/sports">Sports</a></span></div></div>
                      <div className="views-row views-row-4 views-row-even">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/pratinanda-2k18"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_DSC_0160.JPG.jpg?itok=q2dzogg2" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/pratinanda-2k18">Pratinanda 2k18</a></span></div></div>
                      <div className="views-row views-row-5 views-row-odd">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/group-photos"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_DSC_0015.JPG.jpg?itok=OX8JA91S" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/group-photos">Group Photos</a></span></div></div>
                      <div className="views-row views-row-6 views-row-even">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/building"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_DSC_3165.JPG.jpg?itok=1Zmx-Z8i" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/building">Building</a></span></div></div>
                      <div className="views-row views-row-7 views-row-odd">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/girls-hostel"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/01_0.jpg?itok=MY9vgwJl" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/girls-hostel">Girls' Hostel</a></span></div></div>
                      <div className="views-row views-row-8 views-row-even">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/boys-hostel"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/01.jpg?itok=q0pCCDuS" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/boys-hostel">Boys' Hostel</a></span></div></div>
                      <div className="views-row views-row-9 views-row-odd">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/vips-vyasa-0"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/_0000_arogyadhama%20boards%203ft%202ft%20low%20resolution.jpg_0.jpg?itok=QdUAU_qn" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/vips-vyasa-0">VIPs in Vyasa</a></span></div></div>
                      <div className="views-row views-row-10 views-row-even views-row-last">
                        <div className="views-field views-field-field-photos">        <div className="field-content"><a href="/convocation"><img typeof="foaf:Image" className="img-responsive" src="https://www.sushruthacollegeofnursing.com/sites/default/files/styles/medium/public/10.jpg?itok=JbPn2bUa" width={220} height={147} alt /></a></div></div>
                        <div className="views-field views-field-title">        <span className="field-content"><a href="/convocation">Convocation</a></span></div></div>
                    </div>
                    <div className="text-center"><ul className="pagination"><li className="active"><span>1</span></li>
                      <li><a title="Go to page 2" href="/photos?page=1">2</a></li>
                      <li className="next"><a title="Go to next page" href="/photos?page=1">next ›</a></li>
                      <li className="pager-last"><a title="Go to last page" href="/photos?page=1">last »</a></li>
                    </ul></div>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
        <div className="region region-content-bottom-bottom">
          <section id="block-block-9" className="block block-block clearfix">
            <div className="center">
              <p className="lead">Are you ready to earn your nursing degree?
                <a className="btn" href="/contact">Contact Us to know more</a></p>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    </div>

  </>)
}
export default Photos